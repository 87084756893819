import Button from '@material-ui/core/Button'
import React from 'react'
import withChargebee from '../hocs/withChargebee'

const CustomerPortalButton = ({ depsLoading, showCustomerPortal }) => (
  <>
    <Button
      color='primary'
      disabled={depsLoading}
      onClick={showCustomerPortal}
      id='customerPortalButton'
    >
      Manage account
    </Button>
  </>
)

export default withChargebee(CustomerPortalButton)
