import Button from '@material-ui/core/Button'
import DownloadForm from './DownloadForm'
import DownloadUrlInput from './DownloadUrlInput'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    margin: 8,
    minWidth: '30%',
    height: 60,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%'
    }
  }
})

const StartDownloadForm = props => {
  const { classes, autoFocus, index, wayback } = props

  let attributes = {}
  if (wayback) {
    attributes = {
      timestamp: 'latest',
      page: 0
    }
  }

  return (
    <DownloadForm {...attributes}>
      <DownloadUrlInput autoFocus={autoFocus} index={index} withButton wayback={wayback} />
      <Button type='submit' variant='contained' size='large' color='primary' className={classes.button} itemProp='potentialAction' id={`startDownloadButton-${index}`}>
        {!wayback &&
          <>Download website</>
        }
        {wayback &&
          <>Download history</>
        }
      </Button>
    </DownloadForm>
  )
}

export default withStyles(styles)(StartDownloadForm)
