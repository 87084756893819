import { FaFacebookF, FaLinkedin, FaTwitter } from 'react-icons/fa'

import { IconContext } from 'react-icons'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    fontFamily: '"Helvetica Neue", Helvetica, Helvetica, Arial, sans-serif',
    fontSize: 11,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    zIndex: 2147483647 /* max int32 z-index */,
    pointerEvents: 'none',
    '@media screen and (max-width: 800px)': {
      alignItems: 'flex-end'
    },
    '& strong': {
      fontSize: 14
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 800px)': {
      flexDirection: 'row',
      width: '100vw',
      justifyContent: 'space-evenly'
    }
  },
  button: {
    width: 50,
    height: 50,
    padding: '15px 10px',
    marginBottom: 5,
    cursor: 'pointer',
    textAlign: 'center'
  },
  hasShareCount: {
    padding: '10px 10px 16px'
  },
  circle: {
    borderRadius: 50
  },
  shares: {
    background: 'white',
    color: 'black'
  },
  facebook: {
    background: 'rgb(59, 89, 152)',
    color: 'white'
  },
  twitter: {
    background: 'rgb(0, 172, 237)',
    color: 'white'
  },
  linkedin: {
    background: 'rgb(0, 123, 182)',
    color: 'white'
  },
  producthunt: {
    background: 'rgb(218, 85, 47)',
    color: 'white',
    paddingTop: 5,
    '& span': {
      position: 'relative',
      top: -7.5
    }
  },
  logo: {
    color: 'white'
  }
})

const ShareWidget = ({ classes }) => (
  <div className={classes.root}>
    <IconContext.Provider value={{ className: classes.logo, size: 20 }}>
      <div className={classes.container}>
        <div className={`${classes.button} ${classes.circle} ${classes.shares} ${classes.hasShareCount}`}>
          <span>
            <strong>6k</strong>
            <br />Shares
          </span>
        </div>
        <div className={`${classes.button} ${classes.circle} ${classes.facebook} ${classes.hasShareCount}`}>
          <FaFacebookF />
          <span>1.7k</span>
        </div>
        <div className={`${classes.button} ${classes.circle} ${classes.twitter} ${classes.hasShareCount}`}>
          <FaTwitter />
          <span>2.5k</span>
        </div>
        <div className={`${classes.button} ${classes.circle} ${classes.linkedin}`}>
          <FaLinkedin />
        </div>
        <div className={`${classes.button} ${classes.circle} ${classes.producthunt} ${classes.hasShareCount}`}>
          <svg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'>
            <g fill='none' fillRule='evenodd'>
              <path d='M40 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0s20 8.954 20 20' fill='#DA552F' />
              <path d='M22.667 20H17v-6h5.667a3 3 0 0 1 0 6m0-10H13v20h4v-6h5.667a7 7 0 1 0 0-14' fill='#FFF' />
            </g>
          </svg>
          <span>1.8k</span>
        </div>
      </div>
    </IconContext.Provider>
  </div>
)

export default withStyles(styles)(ShareWidget)
