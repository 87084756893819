import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const Component = ({ icon, title, perrow, children }) => {
  let sm, md

  if (perrow === 4) {
    sm = 6
    md = 3
  } else if (perrow === 3) {
    md = 4
  } else {
    sm = 6
  }

  return (
    <Grid item align='center' xs={8} sm={sm} md={md}>
      {icon}
      <Typography variant='h3' gutterBottom>
        {title}
      </Typography>
      <Typography variant='body1' align='justify' color='textSecondary' gutterBottom>
        {children}
      </Typography>
    </Grid>
  )
}

Component.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  perrow: PropTypes.number
}

export default Component
