import AuthContext from '../../utils/auth0/AuthContext'
import Button from '@material-ui/core/Button'
import CustomerPortalButton from '../controls/CustomerPortalButton'
import Hidden from '@material-ui/core/Hidden'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  accountHeader: {
    position: 'absolute',
    right: 20,
    top: 13
  }
})

const AccountHeader = ({ classes }) => {
  return (
    <AuthContext.Consumer>
      {({ isAuthenticated, login, logout, getUserName }) => (
        <Typography variant='body1' className={classes.accountHeader}>
          {!isAuthenticated &&
            <>
              <Button color='primary' href='mailto:support@websitedownloader.io'>Support</Button>
              <Button onClick={() => login()} color='primary' id='headerLoginButton'>
                Login
              </Button>
            </>
          }
          {isAuthenticated &&
            <>
              {getUserName() &&
                <Hidden lgDown>Hi, {getUserName()}</Hidden>
              }
              <Button color='primary' href='mailto:support@websitedownloader.io'>Support</Button>
              <CustomerPortalButton />
              <Button color='primary' onClick={logout} id='headerLogoutButton'>Logout</Button>
            </>
          }
        </Typography>
      )}
    </AuthContext.Consumer>
  )
}

export default withStyles(styles)(AccountHeader)
