import Grid from '@material-ui/core/Grid'
import { IconContext } from 'react-icons'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  secondaryBackground: theme.mixins.secondaryBackground,
  section: theme.mixins.section,
  subSection: theme.mixins.subSection,
  textPrimary: theme.mixins.textPrimary,
  textSecondary: theme.mixins.textSecondary,
  columnIcon: theme.mixins.columnIcon
})

const Component = ({ classes, title, subTitle, children, background, perrow, iconColor }) => {
  iconColor = iconColor ? classes[iconColor] : classes.textSecondary

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      spacing={0}
      className={background === 'backgroundSecondary' ? classes.secondaryBackground : ''}
    >
      <Grid item xs={11} md={10} lg={8} className={classes.section}>
        <Typography variant='h2' align='center' gutterBottom>
          {title}
        </Typography>
        <Typography variant='h4' align='center' color='textSecondary' gutterBottom>
          {subTitle}
        </Typography>
        <Grid className={classes.subSection}
          container
          direction='row'
          justify='center'
          alignItems='flex-start'
          spacing={40}
        >
          <IconContext.Provider value={{ className: `${classes.columnIcon} ${iconColor}`, size: 70 }}>
            {children &&
              React.Children.map(children, child => (
                React.cloneElement(child, { perrow: perrow })
              ))
            }
          </IconContext.Provider>
        </Grid>
      </Grid>
    </Grid>
  )
}

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  background: PropTypes.string,
  iconColor: PropTypes.string,
  perrow: PropTypes.number
}

export default withStyles(styles)(Component)
