import Grid from '@material-ui/core/Grid'
import Header from './Header'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import ReactFitText from 'react-fittext'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  primaryBackground: theme.mixins.primaryBackground,
  alternativeBackground: theme.mixins.alternativeBackground,
  fullWidth: theme.mixins.fullWidth,
  paper: theme.mixins.paper,
  hero: {
    height: '80vh',
    minHeight: 440,
    maxHeight: 1200
  },
  h1: {
    marginTop: '2vh'
  }
})

const Component = ({ classes, icon, title, subTitle, frontPage, children, wayback }) => (
  <div className={wayback ? classes.alternativeBackground : classes.primaryBackground}>
    <Header frontPage={frontPage} />
    <Grid className={classes.hero}
      container
      direction='column'
      justify='center'
      alignItems='center'
      spacing={0}
    >
      <Grid item xs={10} md={8} lg={6} itemScope itemType='http://schema.org/downloadaction'>
        {icon}
        <ReactFitText compressor={1}>
          <Typography variant='h1' align='center' className={classes.h1} itemProp='name' gutterBottom>
            {title}
          </Typography>
        </ReactFitText>
        <ReactFitText compressor={3}>
          <Typography variant='h4' align='center' color='textSecondary' itemProp='description' gutterBottom>
            {subTitle}
          </Typography>
        </ReactFitText>
        <Paper className={classes.paper}>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
            spacing={0}
          >
            <Grid item xs={12} align='center' className={classes.fullWidth}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
)

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  frontPage: PropTypes.bool,
  wayback: PropTypes.bool
}

export default withStyles(styles)(Component)
