import AccountHeader from '../containers/AccountHeader'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'gatsby'
import MaterialLink from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  appbar: {
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center'
    }
  }
})

const Component = ({ classes, frontPage }) => (
  <>
    <Hidden xsDown>
      <AppBar position='static' color='default' className={classes.appbar}>
        {frontPage &&
          <Toolbar>
            <Typography variant='body1' color='inherit' align='center'>
              New: <Hidden smDown>You can now use </Hidden><MaterialLink component={Link} to='/wayback-machine-downloader/' title='Wayback Machine Downloader' color='primary'>Wayback Machine Downloader</MaterialLink><Hidden smDown> to download internet history!</Hidden>
            </Typography>
          </Toolbar>
        }
        <AccountHeader />
      </AppBar>
    </Hidden>
    <Hidden mdUp>
      <AccountHeader />
    </Hidden>
  </>
)

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  frontPage: PropTypes.bool
}

export default withStyles(styles)(Component)
