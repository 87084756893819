import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  section: theme.mixins.section,
  primaryBackground: theme.mixins.primaryBackground,
  alternativeBackground: theme.mixins.alternativeBackground,
  fullWidth: theme.mixins.fullWidth,
  paper: theme.mixins.paper,
  boxedSection: {
    [theme.breakpoints.up('sm')]: {
      margin: '5vh 0',
      width: '80%'
    }
  }
})

const Component = ({ classes, title, children, wayback }) => {
  const background = wayback ? classes.alternativeBackground : classes.primaryBackground

  return (
    <Grid
      container
      direction='column'
      justify='center'
      alignItems='center'
      spacing={0}
    >
      <div className={`${background} ${classes.boxedSection}`}>
        <Grid item xs={10} md={10} lg={7} className={classes.section} itemScope itemType='http://schema.org/downloadaction'>
          <Typography variant='h2' align='center' itemProp='description' gutterBottom>
            {title}
          </Typography>
          <Paper className={classes.paper}>
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'
              spacing={0}
            >
              <Grid item xs={12} align='center' className={classes.fullWidth}>
                {children[0]}
              </Grid>
            </Grid>
          </Paper>
          {children[1]}
        </Grid>
      </div>
    </Grid>
  )
}

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  frontPage: PropTypes.bool
}

export default withStyles(styles)(Component)
